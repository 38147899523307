<template>
  <div class="container map-warp">
    <div class="map-content">
      <!-- <img :src="require('@/assets/image/map.png')" alt="" /> -->
      <baidu-map
        style="height: 100%; width: 100%"
        @click="getClickInfo"
        @moving="syncCenterAndZoom"
        @moveend="syncCenterAndZoom"
        @zoomend="syncCenterAndZoom"
        scroll-wheel-zoom
        :center="center"
        :zoom="zoom"
        :min-zoom="3"
        :max-zoom="25"
        ak="M53VsY6rYerqqOgYQxv9Bu861VXXnhef"
        @ready="mapHandler"
      >
        <bm-marker
          :position="center"
          :dragging="false"
          animation="BMAP_ANIMATION_BOUNCE"
        >
          <!-- bm-label 显示标注点名称  -->
          <bm-label
            :content="addressName"
            :offset="{ width: -35, height: 30 }"
          />
        </bm-marker>
        <bm-view
          class="map"
          style="width: 100%; height: 100%; flex: 1"
        ></bm-view>

        <bm-control :offset="{ width: '50px', height: '10px' }">
          <bm-auto-complete :sugStyle="{ zIndex: 999999 }" v-model="keyword" @confirm="handleConfirm">
            <van-search
              v-model="inputValue"
              placeholder="搜索位置信息"
              background="rgb(248, 248, 248)"
              @change="confirmAddress"
            />
          </bm-auto-complete>
        </bm-control>

        <!-- 手动放大缩小到省市街道 位置：右下方-->
        <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" />
      </baidu-map>
      <div class="button-wrap">
        <span class="van-button gray" @click="handleCancel">取 消</span>
        <span class="van-button blue" @click="handleSure">确 定</span>
      </div>
    </div>
  </div>
</template>

<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue"; //百度地图接入
import BmView from "vue-baidu-map/components/map/MapView.vue";
import BmLocalSearch from "vue-baidu-map/components/search/LocalSearch.vue";

import BmNavigation from "vue-baidu-map/components/controls/Navigation";
import BmMarkerClusterer from "vue-baidu-map/components/extra/MarkerClusterer";
import BmMarker from "vue-baidu-map/components/overlays/Marker";

import BmInfoWindow from "vue-baidu-map/components/overlays/InfoWindow"; //标注弹窗
import BmAutoComplete from "vue-baidu-map/components/others/AutoComplete";
import BmControl from "vue-baidu-map/components/controls/Control";
let geocoder;
export default {
  components: {
    BaiduMap,
    BmView,
    BmLocalSearch,
    BmNavigation,
    BmMarkerClusterer,
    BmMarker,
    BmInfoWindow,
    BmAutoComplete,
    BmControl,
  },
  data() {
    return {
      BMap: {},
      map: {},
      // 地图放大等级
      zoom: 14,
      inputValue: "",
      keyword: "",
      activeIndex: 0,
      center: { lng: 0, lat: 0 },
      addressName: "",
    };
  },
  created() {},
  mounted() {
  },
  methods: {
    // 选择了详细地址后
    handleConfirm({ item }) {
      const that = this;
	      let queryString = `${item.value.city}${item.value.district}${item.value.business}`;
	      var myGeo = new BMap.Geocoder();
	      myGeo.getPoint(queryString, function (point) {
	        if (point) {
       
            that.$nextTick(() => {
              that.$set(that.center,'lng',point.lng)
              that.$set(that.center,'lat',point.lat)
              that.getCurAddress(that.center);
            })
	        }
	      });
    },
    // 搜索框的搜索事件
    confirmAddress(e) {
      if (this.inputValue != "") {
        this.keyword = this.inputValue;
      }

      // 为啥使用延时？？
      // 因为上面搜索框是change事件，变化的太快了看起来效果不好所以添加了延时
      // setTimeout(() => {
      //   //搜索时把需要标点的地址传入local.search中
      //   var local = new BMap.LocalSearch(this.map, {
      //     renderOptions: { map: this.map },
      //   });
      //   local.search(this.keyword);
      //   console.log(local, "ee");
      // }, 500);
  
    },

    // 地区组件的markersset事件
    setAddressList(e) {
      console.log(e, "1212");
    },

    // handler() {
    //   this.$nextTick(() => {
    //     const { lng, lat } = this.$route.query;
    //     this.center.lng = Number(lng);
    //     this.center.lat = Number(lat);
    //     // 地图放大等级
    //     this.zoom = 20;
    //     this.getCurAddress(this.center);
    //   });
    // },
    async mapHandler({ BMap, map }) {
      console.log(11111)
      //保存百度地图类
      this.BMap = BMap;
      //保存地图对象
      this.map = map;
      //如果一开始坐标存在(编辑的时候)
      const { lng, lat } = this.$route.query;

      if (lng && lat) {
        //设置坐标
        this.center.lng = lng;
        this.center.lat = lat;
        // 地图放大等级
        this.zoom = 20;
        this.getCurAddress(this.center);
      }
    },
    getClickInfo(e) {
      console.log(e, "3333");
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
      this.getCurAddress(this.center);
    },
    getCurAddress({ lng, lat }) {
      var point = new BMap.Point(lng, lat);
      var gc = new BMap.Geocoder();
      let _this = this;
      gc.getLocation(point, function (rs) {
        console.log(rs, "rsss");
        _this.addressName = rs.address;
      });
    },
    handleLi(index) {
      this.activeIndex = index;
    },
    syncCenterAndZoom(e) {
      const { lng, lat } = e.target.getCenter();
      this.center.lng = lng;
      this.center.lat = lat;
      this.zoom = e.target.getZoom();
    },
    handleSure() {
      this.$router.push({
        path: "/attendanceClock",
        query: {
          lat: this.center.lat,
          lng: this.center.lng,
        },
      });
    },
    handleCancel() {
      this.$router.go(-1)
    }
  },
};
</script>

<style lang="less">
.map-warp {
  background: rgb(248, 248, 248);
  letter-spacing: 0px;
  position: relative;
  .map-content {
    width: 100vw;
    height: 100vh;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .BMap_noprint {
      width: 100%;
      padding: 10px 0.32rem;
      .van-search {
        background: #f5f5f5;
        border: 1px solid #d5d5d5;
        border-radius: 8px;
        height: 1.06667rem;
        line-height: 1.06667rem;
        .van-search__content {
          background-color: transparent;
        }
      }
    }
    .button-wrap {
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      top: 100px;
      padding: 0 15px;
      .gray {
        background: rgb(196, 196, 196);
      }
      .blue {
        background: rgb(38, 120, 255);
      }
      .van-button {
        width: 46px;
        height: 22px;
        line-height: 22px;
        font-size: 12px;
        border-radius: 3px;
        color: #fff;
      }
    }
  }
  .map-ul {
    overflow: auto;
    height: calc(100vh - 500px);
  }
  .map-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    border-bottom: 0.5px solid rgb(196, 196, 196);
    padding: 0 24px;
    .map-li-right {
      > img {
        width: 16px;
        height: 12px;
        object-fit: cover;
      }
    }
  }
  .whiteSpace300 {
    display: block;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 300px;
  }
}
</style>
